import React, { Component } from "react";
import './App.css';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      exportMode: false,
      data: [
        {
          'heading': "Lace on gathered fabric",
          'items': [
            {
              'style': 'WG3861',
              'name': 'Sheer Lace and Tulle Ball Gown Wedding Dress',
              'description': '',
              'image': 'https://img.davidsbridal.com/is/image/DavidsBridalInc/WG3861_IVYBLACK_CORE_PROD2_096?wid=607',
              'url': 'https://www.davidsbridal.com/product/sheer-lace-and-tulle-ball-gown-wedding-dress-wg3861?defining_color=1759'
            },
            {
              'style': 'AI25050112',
              'name': 'As-Is A-Line Wedding Dress with Double Straps',
              'description': '',
              'image': 'https://img.davidsbridal.com/is/image/DavidsBridalInc/MS251177_IVYCHAMP_MS_PROD9_078?wid=1080&hei=1620&fit=constrain,1&resmode=sharp2&op_usm=2.5,0.3,4',
              'url': 'https://www.davidsbridal.com/product/as-is-a-line-wedding-dress-with-double-straps-ai25050112?defining_color=375'
            },
            {
              'style': 'MS251266',
              'name': 'Embroidered Puff Sleeve Empire Waist Wedding Dress',
              'description': '',
              'image': 'https://img.davidsbridal.com/is/image/DavidsBridalInc/MS251266_IVYSTONE_MS_PROD2_022?wid=1080&hei=1620&fit=constrain,1&resmode=sharp2&op_usm=2.5,0.3,4',
              'url': 'https://www.davidsbridal.com/product/embroidered-puff-sleeve-empire-waist-wedding-dress-ms251266?defining_color=1021'
            },
            {
              'style': 'CWG959',
              'name': 'Cap Sleeve V-Back Beaded Ball Gown Wedding Dress',
              'description': '',
              'image': 'https://img.davidsbridal.com/is/image/DavidsBridalInc/CWG959_IVYCHAMP_OLEG_PROD10_2973?wid=607',
              'url': 'https://www.davidsbridal.com/product/cap-sleeve-v-back-beaded-ball-gown-wedding-dress-cwg959?defining_color=375'
            },
          ]
        },
        {
          'heading': "Belt",
          'items': [
            {
              'style': 'V3848',
              'name': 'Cummerbund Satin Ball Gown Wedding Dress',
              'description': "this is the closest I'm getting to gathered fabric belts",
              'image': 'https://img.davidsbridal.com/is/image/DavidsBridalInc/V3848_IVORY_DBSTU_PROD11_V2_031_detail?wid=607',
              'url': 'https://www.davidsbridal.com/product/satin-cummerbund-ball-gown-wedding-dress-v3848?defining_color=165'
            },
            {
              'style': 'AI10012546',
              'name': 'As-Is Organza Wedding Ball Gown with Beading',
              'description': 'beaded belt on a ball gown',
              'image': 'https://img.davidsbridal.com/is/image/DavidsBridalInc/WG3830_SOFTWHITE_CORE_PROD8_V2_1636?wid=1080&hei=1620&fit=constrain,1&resmode=sharp2&op_usm=2.5,0.3,4',
              'url': 'https://www.davidsbridal.com/product/as-is-organza-wedding-ball-gown-with-beading-ai10012546?defining_color=361'
            },
            {
              'style': 'AI14010790',
              'name': 'As Is Floral Glitter Tea-Length Wedding Dress',
              'description': 'beaded belt, corsetty/ribbed bodice',
              'image': 'https://img.davidsbridal.com/is/image/DavidsBridalInc/CWG903_IVYSTONE_OLEG_PROD6_V2_048?wid=1080&hei=1620&fit=constrain,1&resmode=sharp2&op_usm=2.5,0.3,4',
              'url': 'https://www.davidsbridal.com/product/as-is-floral-glitter-tea-length-wedding-dress-ai14010790?defining_color=1021'
            },
            {
              'style': 'MS251209',
              'name': 'Corset Bodice Tiered Chiffon A-Line Wedding Dress',
              'description': 'Corset boned dress with lace "belt"',
              'image': 'https://img.davidsbridal.com/is/image/DavidsBridalInc/MS251209_IVORY_MS_PROD9_126?wid=607',
              'url': 'https://www.davidsbridal.com/product/corset-bodice-tiered-chiffon-a-line-wedding-dress-ms251209?defining_color=165'
            },
            {
              'style': 'CWG888',
              'name': 'Embroidered V-Neck Wedding Dress with Tulle Skirt',
              'description': 'different "belt" vibe, wide fabric join, not gathered. maybe a bad idea for a corset',
              'image': 'https://img.davidsbridal.com/is/image/DavidsBridalInc/CWG888_IVORY_OLEG_PROD12_48?wid=607',
              'url': 'https://www.davidsbridal.com/product/embroidered-long-sleeve-tulle-wedding-dress-slcwg888?defining_color=165'
            },
            {
              'style': 'MSLLBSWG842',
              'name': 'Long Sleeve Applique Chiffon Modest Wedding Dress',
              'description': 'beaded beltish join',
              'image': 'https://img.davidsbridal.com/is/image/DavidsBridalInc/MSLLBSWG842_IVORY_SIG_PROD2_118?wid=607',
              'url': 'https://www.davidsbridal.com/product/long-sleeve-applique-chiffon-modest-wedding-dress-msllbswg842?defining_color=121'
            },
            {
              'style': 'SDWG0801',
              'name': 'Scalloped V-Neck Corded Lace Bodice A-Line Dress',
              'description': 'ribbon belt',
              'image': 'https://img.davidsbridal.com/is/image/DavidsBridalInc/SDWG0801_SOFTWHITE_DBSTU_PROD11_V2_058?wid=607',
              'url': 'https://www.davidsbridal.com/product/scalloped-v-neck-corded-lace-bodice-a-line-dress-sdwg0801?defining_color=361'
            },
          ]
        },
        {
          'heading': "Diamond point/princess",
          'items': [
            {
              'style': 'T8017',
              'name': 'Tulle Wedding Dress with Beaded Satin Bodice',
              'description': 'the best princess style dress I could find',
              'image': 'https://img.davidsbridal.com/is/image/DavidsBridalInc/NT8017_WHITE_CORE_PROD12_V2_080?wid=607',
              'url': 'https://www.davidsbridal.com/product/tulle-wedding-dress-with-beaded-satin-bodice-t8017?defining_color=165'
            },
            {
              'style': 'CWG749',
              'name': 'Oleg Cassini Wedding Ball Gown with Lace Appliques',
              'description': 'I think this is also princess style under the lace',
              'image': 'https://img.davidsbridal.com/is/image/DavidsBridalInc/CWG749_IVYWHISPERPINK_OLEG_PROD8_V2_073?wid=607',
              'url': 'https://www.davidsbridal.com/product/oleg-cassini-wedding-ball-gown-with-lace-appliques-cwg749?defining_color=1978'
            },
          ]
        }
      ]
    };
  }

  toggleExportMode() {
    this.setState({ exportMode: !(this.state.exportMode) })
  }

  handleDragStart = (e, item) => { 
    this.setState({ draggingItem: item }); 
    e.dataTransfer.setData('text/plain', ''); 
  }; 

  handleDragEnd = () => { 
    this.setState({ draggingItem: null }); 
  }; 

  handleDragOver = (e) => { 
    e.preventDefault(); 
  }; 

  handleDrop = (e, targetItem, index) => { 
    const { draggingItem, data } = this.state; 
    if (!draggingItem) return; 

    const currentIndex = data[index].items.indexOf(draggingItem); 
    const targetIndex = data[index].items.indexOf(targetItem); 

    if (currentIndex !== -1 && targetIndex !== -1) { 
      data[index].items.splice(currentIndex, 1); 
      data[index].items.splice(targetIndex, 0, draggingItem); 
      this.setState({ data }); 
    } 
  };

  handleRemove = (e, targetItem, index) => { 
    const { data } = this.state; 

    const targetIndex = data[index].items.indexOf(targetItem);

    if (targetIndex !== -1) { 
      data[index].items.splice(targetIndex, 1);
      this.setState({ data }); 
    } 
  };

  card(item, index) {
    return (
      <div className="col-sm-12 col-md-3">
      <div 
        className="card"
        key={item.style}
        draggable
        onDragStart={(e) => this.handleDragStart(e, item)} 
        onDragEnd={this.handleDragEnd} 
        onDragOver={this.handleDragOver} 
        onDrop={(e) => this.handleDrop(e, item, index)} 
      >
        <img className="card-img-top" src={item.image} alt={item.name}/>
        <div className="card-body">
          <h5 className="card-title">#{item.style}: {item.name}</h5>
          <p className="card-text">{item.description}</p>
          <a href={item.url} className="btn btn-outline-primary">David's Bridal<span className='visually-hidden'> {item.name}</span></a>
          <button className="btn btn-outline-danger ms-2" onClick={(e) => this.handleRemove(e, item, index)}>Remove<span className='visually-hidden'> {item.name}</span></button>
        </div>
      </div>
      </div>
    )
  }

  render() {
    const rankings = this.state.data.map((category, index) => 
      <div key={index} className="">
        <div className="row mt-5 mb-2">
          <h2>{category.heading}</h2>
        </div>
        <div className="row">
            {this.state.data[index].items.map((item) => this.card(item, index))}
        </div>
      </div>
    );
    const exportText = this.state.data.map((category, index) => 
      <div key={index}>
        <h5>{category.heading}</h5>
        <ul>
          {this.state.data[index].items.map((item) => 
            <li key={item.style}>Style# {item.style}: {item.name}<br/>{item.url}</li>
          )}
        </ul>
      </div>
    );
    return (
      this.state.exportMode?
      (<div className="container mt-5 mb-5">
        <p>Copy this text and send it to Eli:</p>
        <hr/>
        {exportText}
        <div className="mt-5 d-flex">
          <button className="btn btn-outline-dark ms-auto" onClick={() => this.toggleExportMode()}>Close</button>
        </div>
      </div>)
      :
      (<div className="container mb-5">
        <div>
          {rankings}
        </div>
        <div className="mt-5 d-flex">
          <button className="btn btn-primary ms-auto" onClick={() => this.toggleExportMode()}>Send list to Eli</button>
        </div>
      </div>)
    );
  }
}

export default App;
